<template>
<div class="position-relative zindex-999">
    <div class="col-md-4" style="margin: 70px auto;">
        <div class="form-logo">
            <center>
                <router-link to="/"><img src="@/assets/images/mn-shadow.png" alt="MN Logo" width="80"></router-link>
                <h1 class="form-heading mt-3 text-dark">Come on baby login now</h1>
                <p class="form-font-family text-dark">or <router-link to="/" style="color: #e16259;">back to home <i class="fas fa-home"></i></router-link></p>
            </center>
        </div>
        <div class="form-content mt-4 p-4">
            <form action="#" @submit.prevent="submit">
                <div class="form-group">
                    <label for="" class="text-secondary">E-Mail Address</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="far fa-envelope"></i></div>
                        </div>
                        <input type="email" class="form-control" v-model="form.email" :class="{'is-invalid': formErrors.email}" placeholder="Type your email...">
                        <div class="invalid-feedback" v-if="formErrors.email">{{formErrors.email[0]}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="" class="text-secondary">Password</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fas fa-key"></i></div>
                        </div>
                        <input type="password" class="form-control" v-model="form.password" :class="{'is-invalid': formErrors.password}" placeholder="Type your password...">
                        <div class="invalid-feedback" v-if="formErrors.password">{{formErrors.password[0]}}</div>
                    </div>
                </div>

                
                <div class="form-group">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="remember" id="remember">

                        <label class="form-check-label form-font-family font-weight-bold text-secondary" for="remember">
                            Remember Me
                        </label>
                    </div>

                    <a href="#" class="form-font-family">
                        I forgot my password...
                    </a>
                </div>

                <button type="submit" class="btn badge-bgColor btn-sm w-100 py-2" :disabled="btnLoading">
                    <center>
                        <div class="d-flex justify-content-center">
                            <span class="font-weight-bold">Login</span>
                            <span v-if="btnLoading">
                                <passingThree/>
                            </span>
                        </div>
                    </center>
                </button>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import passingThree from '@/components/loadings/passingThree'
export default {
    title: 'Login - Muhamad Ndaru | Portfolio',
    data(){
        return{
            form: {
                email: '',
                password: ''
            }
        }
    },
    components: {passingThree},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors'
        })
    },
    methods: {
        submit(){
            this.$store.dispatch('auth/login', this.form)
        }
    }
}
</script>

<style lang="scss" scoped>
.form-heading {
    font-family: quicksand-bold;
    font-size: 30px;
}

// .btn-login {
//     background: #C2E59D !important;
//     color: $textContent !important;
//     font-family: quicksand-bold;
// }

// .btn-login:hover {
//     transition: 0.4s;
//     background: #99c56a !important;
// }

@media screen and (max-width: 768px) {
    .form-heading {
        font-size: 25px;
    }

    .form-content {
        box-shadow: none;
        border-radius: 0;
    }
}
</style>